<template>
    <h1>Page not found!</h1>
    <p>We looked far and wide, and found no big red X on the sand...</p>
    <router-link to="/">Go Back</router-link>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
h1 {
    color: red;
}
</style>