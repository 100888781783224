<template>
    <!-- <h1>{{ currentPost }}</h1> -->
    <component :is="currentComponent"></component>
</template>

<script>
import posts from '../data/posts.json';
import NotFound from '@/views/NotFound.vue';

export default {
    name: "BlogPost",
    data() {
        return {
            currentPost: this.getPostInfo(this.$route.params.id),
            postName: this.getPostInfo(this.$route.params.id, "title"),
            currentComponent: null
        }
    },
    created() {
        // this.currentPost = this.getPostInfo(this.$route.params.id);
        // this.postName = this.getPostInfo(this.$route.params.id, "title");
        this.renderCurrentPost();
    },
    methods: {
        getPostInfo(id, key = null) {
            let matchingPost = null;
            posts.forEach(i => {
                if (i.id === id) {
                    matchingPost = i;
                }
            });
            if (key !== null) {
                return matchingPost ? matchingPost[key] : null; // Handle non-existent post
            } else {
                return matchingPost;
            }
        },
        async renderCurrentPost() {
            const componentName = this.currentPost ? this.currentPost["component"] : null;

            if (!this.currentPost || !componentName) {
                // If the post doesn't exist, set to NotFound component
                this.currentComponent = NotFound;
                return;
            }

            try {
                const component = await import(`@/blogContent/${componentName}.vue`);
                this.currentComponent = component.default;
            } catch (error) {
                console.error("Failed to load component:", error);
                // If component fails to load, set to NotFound component
                this.currentComponent = NotFound;
            }
        }
    },

}
</script>