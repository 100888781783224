<template>
  <div class="blog-container">
    <h1>Huge W.I.P. Right now...</h1>
    <div class="posts-div">
      <div class="post-item" v-for="post in posts" :key="post.id" @click="openPost(post.id)">
        <img :src="resolveImagePath(post.image)" alt="" class="post-image" />
        <div class="post-content">
          <h2 class="post-title">{{ post.title }}</h2>
          <p class="post-desc">{{ post.sDesc }}</p>
          <small class="post-time">{{ formatDate(post.time) }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import posts from '../data/posts.json';

export default {
  data() {
    return {
      posts,
    };
  },
  methods: {
    resolveImagePath(image) {
      // Check if the image starts with @ and prepend the correct path
      if (image.startsWith('@/')) {
        return require(`${image}`);
      }
      return require(`@/assets/${image}`); // Assuming the image is just the filename
    },
    formatDate(beforeDate) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(`${beforeDate}T00:00:00`);
      if (Number.isNaN(date.getTime())) {
        return 'Invalid date!';
      }
      return date.toLocaleDateString('en-US', options);
    },
    openPost(id) {
      this.$router.push({ name: 'BlogPost', params: { id: id } });
    }
  }
};
</script>

<style scoped>
.blog-container {
  padding: 20px;
}

.posts-div {
  display: flex;
  flex-wrap: wrap;
  /* Allows wrapping of items for better responsiveness */
  justify-content: center;
  gap: 20px;
  /* Adds space between posts */
}

.post-item {
  display: flex;
  flex-direction: column;
  /* Stacks image above title and description */
  max-width: 300px;
  /* Limits width for better layout */
  border: 1px solid #ddd;
  /* Adds border to posts */
  border-radius: 8px;
  /* Rounds corners */
  overflow: hidden;
  /* Ensures rounded corners apply to the image as well */
  transition: transform 0.2s;
  /* Adds a hover effect */
}

.post-item:hover {
  transform: scale(1.05);
  /* Slightly enlarges on hover */
}

.post-image {
  width: 100%;
  /* Ensures image takes full width */
  height: auto;
  /* Maintains aspect ratio */
}

.post-content {
  padding: 15px;
  /* Adds padding around text */
  background-color: #fff;
  /* Sets background for text area */
}

.post-title {
  margin: 0 0 10px;
  /* Spacing below title */
  font-size: 1.5em;
  /* Makes title stand out */
}

.post-desc {
  margin: 0 0 10px;
  /* Spacing below description */
  color: #555;
  /* Slightly dimmed color for description */
}

.post-time {
  color: #888;
  /* Dimmed color for time */
  font-size: 0.9em;
  /* Smaller font for time */
}
</style>
